import Vue from 'vue'
import Vuex from 'vuex'
import {auth, persistence, db, database} from '../firebase'
import router from "../router";
import * as d3 from "d3";
import {req} from "vuelidate/lib/validators/common";

const shortid = require('shortid');
Vue.use(Vuex)


function handleError({dispatch, commit}, error, user, context) {
    let errorMessage = error.message || "Error desconocido";
    let errorCode = error.code || "Sin código de error";
    let errorLocation = `Función ${context}`;

    // Registra cualquier error, incluyendo detalles específicos.
    dispatch('logError', {
        message: errorMessage,
        location: errorLocation,
        errorType: errorCode,
        user: user.mail || "Sin correo de usuario",
    });

    // Comete el error para actualizar el estado
    commit('setError', error);
}

export default new Vuex.Store({
    state: {
        loading: false,
        //Login vars
        requestVerification: false,
        user: null,
        error: null,
        datos_usuario: null,
        estadoLogin: null,
        id_recaptcha: null,

        responses: {

            mutation: ["YES", "NO", "NA"],
            disease: ['ALL', 'AP CML', 'BP CML', 'CP CML', 'CML', 'Others', 'NA'],
            disease_graphs: ['ALL', 'AP_CML', 'BP_CML', 'CP_CML', 'CML', 'Others', 'NA'],
            treatment_line: [0, 1, 2, 3, 4, 5, 6],
            dosages: ['0', '<15', '15', '30', '45'],
            treatment_status: ['On treatment', 'Finished'],
            status_dosages: ['New ITC', 'On going', 'On Hold', 'Finished'],
            status_dosages_graphs: ['New_ITC', 'On_going', 'On_Hold', 'Finished'],
            resistant: ['YES', 'NO', 'NA'],
            cart_center: ['YES', 'NO'],
        },

        responses_minjuvi: {

            // Monjuvi initial data
            treatment_line: [0, 1, 2, 3, 4, 5, 6],
            risk_factors: ['TRANSFORMED LYMPHOMA', 'DOBLE HIT', 'TRIPLE HIT', 'NA'],
            ipi: ['1-2', '3-5', 'NA'],
            disease_type: ['REFRACTORY', 'RELAPSE'],
            previous_treatment: ['POLA+BR', 'CHEMOTHERAPY', 'CAR-T', 'BISPECIFICS', 'ASCT'],

            // Monthly data
            status_dosages: ['New ITC', 'On going', 'On Hold', 'Finished'],
            status_itc: ['On treatment', 'Finished'],
            status_dosages_graphs: ['New_ITC', 'On_going', 'On_Hold', 'Finished'],
            subsequent_treatments: ['POLA+BR', 'CHEMOTHERAPY', 'CAR-T', 'BISPECIFICS', 'ASCT'],
            end_reason: ['PROGRESSION', 'TOXICITY', 'CAR-T', 'CLINICAL_TRIAL', 'WITHOUT_TREATMENT'],

        },

        solution_section: [
            'iclusig',
            'minjuvi'
        ],

        info_section: [
            'Monthly patients per status',
            'Monthly patients per disease',
            'Monthly patients per line',
            'Monthly patients per dosage',
            'Monthly average of months of treatment',
            'Patients per resistance'
        ],

        info_section_minjuvi: [
            'Monthly patients per status',
            'Monthly patients per line',
            'Grouped treatments evolution per line',
        ],

        scope_section: [
            'IBERIA',
            'PORTUGAL',
            'SPAIN',
            'PT - KAM - 01',
            'PT - KAM - 02',
            'SP - KAM - 01',
            'SP - KAM - 02',
            'SP - KAM - 03',
            'SP - KAM - 04',
            'SP - KAM - 05',
            'SP - KAM - 06',
            'SP - KAM - 07',
            'SP - KAM - 08',
            'SP - KAM - 09',],

        scope_area: [
            'ALL',
            'Spain - Center',
            'Spain - East',
            'Spain - North',
            'Spain - South'],

        scope_disease_minjuvi: ['TOTAL', 'REFRACTORY', 'RELAPSE'],
        scope_country: ['IBERIA', 'SPAIN', 'PORTUGAL'],
        hosp: {},
        kams: {},

        tableShowing: 1,
        hospital_id: null,
        itc_id: null,
        dosage_id: null,
        accounts: [],
        centers: {},


    },
    mutations: {

        setExtraData(state, payload) {
            state.extra_data = payload
        },

        loadingFirebase(state, payload) {
            state.loading = payload
        },
        setUser(state, payload) {
            state.user = payload
        },

        setDatosUsuario(state, payload) {
            state.datos_usuario = payload
        },

        setError(state, payload) {
            state.error = payload
        },

        setStatus(state, payload) {
            state.estadoLogin = payload
        },

        setVerification(state, payload) {
            state.requestVerification = payload
        },

        //Set de los datos en array de los centros
        setAccounts(state, payload) {
            state.accounts = payload
        },

        //Set de datos en diccionario de los centros
        setCenters(state, payload) {
            state.centers = payload
        },


        setRecaptcha(state, payload) {
            state.id_recaptcha = payload
        },

        setTable(state, payload) {
            state.tableShowing = payload
        },

        setDosagesData(state, payload) {
            state.dosages_data = payload
        },

        setIdHospital(state, payload) {
            state.hospital_id = payload
        },

        setIdItc(state, payload) {
            state.itc_id = payload
        },

        setIdDosage(state, payload) {
            state.dosage_id = payload
        },
        setHosp(state, payload) {
            state.hosp = payload
        },
        setKams(state, payload) {
            state.kams = payload
        }

    },
    actions: {

        extraData({commit}, extraData) {
            commit('setExtraData', extraData)
        },

        //Funciones para el ID seleccionados para buscarlos en el objeto account
        selectHospital({commit}, id) {
            commit('setIdHospital', id)
        },

        selectItc({commit}, id) {
            commit('setIdItc', id)
        },

        selectDosage({commit}, id) {
            commit('setIdDosage', id)
        },

        //Crear array de los datos partiendo de Objeto
        updateAccountsData({commit}, updated_accounts) {
            var cuentas = Object.values(updated_accounts)
            commit('setAccounts', cuentas)
        },

        changeDosageData({commit}, dosages) {
            commit('setDosagesData', dosages)
        },

        changeTable({commit}, table) {
            commit('setTable', table)
        },


        detectUser({commit}, user) {
            commit('setUser', user)
        },


        datosUsuario({commit}, data) {
            commit('setDatosUsuario', data)
        },

        changeStatus({commit}, data) {
            commit('setStatus', data)
        },


        logError({commit}, errorInfo) {

            const errorData = {
                message: errorInfo.message || 'Error desconocido',
                location: errorInfo.location || 'Ubicación no especificada',
                errorType: errorInfo.errorType || 'Tipo de error no especificado',
                // Asegurarse de que el campo 'user' nunca sea undefined.
                user: errorInfo.user || 'Usuario no disponible',
                timestamp: errorInfo.timestamp || new Date().toISOString(),
                err: errorInfo || null
            };

            // Realiza una última comprobación antes de intentar guardar.
            if (!errorData.user) {
                return;
            }

            const errorId = database.ref().child('errors').push().key;
            database.ref('errors/' + errorId).set(errorData)
                .then()
                .catch((databaseError) => console.error('Error al registrar en la base de datos:', databaseError));
        },

        async getHosp({commit}) {
            var starCountRef = database.ref('/hospitals/');
            starCountRef.on('value', (snapshot) => {
                const data = snapshot.val();
                commit('setHosp', data)
            });
        },

        async getKams({commit}) {
            var starCountRef = database.ref('/cities/');
            starCountRef.on('value', (snapshot) => {
                const data = snapshot.val();
                commit('setKams', data)
            });
        },

        checkLogin({commit, dispatch}, user) {
            var logguedUser;
            var resolver;
            var token = this.state.estadoLogin;

            auth.setPersistence(persistence.Auth.Persistence.SESSION)
                .then(() => {

                    auth.signInWithEmailAndPassword(user.mail, user.pass)
                        .then(function (userCredential) {
                            // User is not enrolled with a second factor and is successfully signed in.
                            // ...
                        })
                        .catch(function (error) {
                            if (error.code === 'auth/multi-factor-auth-required') {

                                resolver = error.resolver;
                                // Ask user which second factor to use.
                                if (resolver.hints[0].factorId ===
                                    persistence.PhoneMultiFactorGenerator.FACTOR_ID) {

                                    var phoneInfoOptions = {
                                        multiFactorHint: resolver.hints[0],
                                        session: resolver.session
                                    };

                                    if (window.recaptchaVerifier === undefined) {
                                        window.recaptchaVerifier = new persistence.RecaptchaVerifier(
                                            'recaptcha-container',
                                            {
                                                'size': 'invisible'
                                            })


                                    } else {
                                        window.recaptchaVerifier.render().then(function (widgetId) {
                                            grecaptcha.reset(widgetId);
                                        })
                                    }


                                    // Ask user for the SMS verification code.
                                    var cred = persistence.PhoneAuthProvider.credential(
                                        token, user.code);

                                    var multiFactorAssertion =
                                        persistence.PhoneMultiFactorGenerator.assertion(cred);
                                    // Complete sign-in.
                                    return resolver.resolveSignIn(multiFactorAssertion)
                                        .then(function (res) {

                                            res.user.getIdTokenResult().then(u_data => {
                                                const userLog = {
                                                    email: user.email,
                                                    uid: user.uid,
                                                    ut: u_data.claims
                                                }
                                                commit('setDatosUsuario', userLog)
                                                commit('setUser', userLog)
                                                commit('setVerification', false)
                                                router.push('/Home')
                                            })

                                            // User successfully signed in with the second factor phone number.
                                        }).catch(() => {

                                            //console.log("ERR -> auth/invalid-verification-code ")
                                            commit('setError', error)
                                            handleError({dispatch, commit}, error, user, "checkLogin");
                                        });

                                } else {
                                    // Unsupported second factor.
                                    commit('setError', error)
                                    handleError({dispatch, commit}, error, user, "checkLogin");
                                }
                            } else if (error.code === 'auth/wrong-password') {
                                // Handle other errors such as wrong password.
                                handleError({dispatch, commit}, error, user, "checkLogin");
                                commit('setError', error)
                            }


                        });


                })
                .catch((error) => {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;

                    // console.log("Error ->", error)
                    handleError({dispatch, commit}, error, user, "checkLogin");
                    commit('setError', error)
                });

        },

        loginUser({commit, dispatch}, user) {
            // console.log("USER -> ", user)
            var logguedUser;
            var resolver;

            auth.setPersistence(persistence.Auth.Persistence.SESSION)
                .then(() => {

                    auth.signInWithEmailAndPassword(user.mail, user.pass)
                        .then(function (userCredential) {
                            // User is not enrolled with a second factor and is successfully signed in.
                            // ...


                        })
                        .catch(function (error) {
                            if (error.code === 'auth/multi-factor-auth-required') {
                                resolver = error.resolver;
                                // Ask user which second factor to use.


                                if (resolver.hints[0].factorId ===
                                    persistence.PhoneMultiFactorGenerator.FACTOR_ID) {
                                    var phoneInfoOptions = {
                                        multiFactorHint: resolver.hints[0],
                                        session: resolver.session
                                    };


                                    if (window.recaptchaVerifier) {
                                        // Restablece el reCAPTCHA existente para evitar errores al renderizarlo nuevamente
                                        window.recaptchaVerifier.clear();
                                        window.recaptchaVerifier = null;
                                    }

                                    // Crea una nueva instancia de RecaptchaVerifier después de limpiar la anterior
                                    window.recaptchaVerifier = new persistence.RecaptchaVerifier(
                                        'recaptcha-container', {
                                            'size': 'invisible'
                                        }
                                    );


                                    var phoneAuthProvider = new persistence.PhoneAuthProvider();
                                    // Send SMS verification code
                                    phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
                                        .then(function (verificationId) {


                                            commit('setVerification', true)
                                            commit('setStatus', verificationId)
                                            return true;
                                        }).catch((error) => {

                                        handleError({dispatch, commit}, error, user, "loginUser");
                                        commit('setError', error)
                                    })


                                } else {
                                    // Unsupported second factor.
                                    handleError({dispatch, commit}, error, user, "LoginUser");
                                    commit('setError', error)
                                    return error;
                                }
                            } else if (error.code === 'auth/wrong-password') {

                                handleError({dispatch, commit}, error, user, "LoginUser");
                                // Handle other errors such as wrong password.
                                commit('setError', error)
                            }
                        });


                })
                .catch((error) => {
                    // Handle Errors here.
                    var errorCode = error.code;
                    var errorMessage = error.message;

                    handleError({dispatch, commit}, error, user, "LoginUser");
                    commit('setError', error)
                });
        },


        // Función unificada para manejar errores y registrarlos adecuadament

        // async checkLogin({commit, dispatch}, user) {
        //     try {
        //         await auth.setPersistence(persistence.Auth.Persistence.SESSION);
        //         const userCredential = await auth.signInWithEmailAndPassword(user.mail, user.pass);
        //         // Procesamiento adicional al iniciar sesión exitosamente
        //         // Por ejemplo, actualizar el estado con el usuario
        //         commit('setUser', { /* datos del usuario */});
        //     } catch (error) {
        //         handleError({dispatch, commit}, error, user, "checkLogin");
        //     }
        // },
        //
        //
        // async loginUser({commit, dispatch}, user) {
        //     try {
        //         await auth.setPersistence(persistence.Auth.Persistence.SESSION);
        //         const userCredential = await auth.signInWithEmailAndPassword(user.mail, user.pass);
        //         // Procesamiento adicional al iniciar sesión exitosamente
        //         // Por ejemplo, redirigir al usuario al Home
        //         router.push('/Home');
        //     } catch (error) {
        //         handleError({dispatch, commit}, error, user, "loginUser");
        //
        //         // Intenta configurar de nuevo reCAPTCHA en caso de fallo
        //         if (!window.recaptchaVerifier) {
        //             window.recaptchaVerifier = new persistence.RecaptchaVerifier('recaptcha-container', {'size': 'invisible'});
        //         } else {
        //             // Manejo de reseteo o configuración del reCAPTCHA si hay error de reCAPTCHA
        //             window.recaptchaVerifier.clear();
        //             window.recaptchaVerifier.render().catch((recaptchaError) => {
        //                 handleError({dispatch, commit}, recaptchaError, user, "loginUser - reCAPTCHA");
        //             });
        //         }
        //     }
        // },


        signOutUser() {
            auth.signOut()
                .then(() => {
                    router.push('/login')
                })
        },

        signOutUserLog({commit}) {
            auth.signOut()
                .then(() => {

                })
        },

        async getAccounts_({commit}, scope) {
            commit('loadingFirebase', true)

            let actual_date = new Date().toISOString().split('T')[0]
            var cuentas = []
            var cuentas_dicc = {}
            var logguedUser;
            var len_dosages = {}


            let query = null


            if ((this.state.user.ut.ut === 1) || ((this.state.user.ut.ut === 3))) {
                query = db.collection('centers').where('status_hospital', "in", scope)

            } else if (this.state.user.ut.ut === 2) {
                query = db.collection('centers')
                    .where('status_hospital', "in", scope)
                    .where('kamMail', '==', this.state.user.email)
            } else if (this.state.user.ut.ut === 0) {
                query = db.collection('centers').where('status_hospital', "in", scope)

            }


            query.get()
                .then(res => {
                    res.forEach(doc => {
                        let itcs = []
                        let itcs_dic = {}
                        //añadimos lo primero la cuenta, para ir poniendole info
                        let cuenta = doc.data()
                        cuenta.flag_update = false


                        new Promise((resolve, reject) => {
                            db.collection(`centers/${doc.id}/itcs`)
                                .get().then(async function (itcs_data) {

                                /*
                                * Hay casos en los que al no existir subcolecciones, se pierden esos
                                * datos. Para evitarlo tenemos que comprobar si los elementos que estamos
                                * iterando tienen esas subcolecciones o no. Para hacer esa comprobacion
                                * tenemos que tenerlos en cuenta en este punto para el primer nivel
                                * */

                                if (itcs_data.docs.length === 0) {

                                    //Los metemos directamente en los datos
                                    doc.data().itc_actives = 0
                                    doc.data().itc_inactives = 0
                                    cuentas.push(doc.data())
                                    cuentas_dicc[doc.id] = doc.data()

                                }

                                itcs_data.forEach(
                                    doc_itc => {

                                        let itc = {}
                                        itc = doc_itc.data()
                                        itc['id'] = doc_itc.id


                                        // La siguiente promesa nos ayudará a obtener la duración del
                                        // tratamiento.
                                        let st_date = doc_itc.data().start_date
                                        let end_date = doc_itc.data().end_date
                                        new Promise((result, rechazado) => {

                                            let dates = []
                                            dates.push(st_date)

                                            if (end_date.length > 0) {

                                                dates.push(end_date)

                                            } else {
                                                dates.push(actual_date)
                                            }


                                            result(dates)
                                            // 0 -> start date, d1
                                            // 1 -> actual date,d2

                                        }).then(dates => {
                                            const monthsBtwnDates = (startDate, endDate) => {
                                                startDate = new Date(startDate);
                                                endDate = new Date(endDate);
                                                return Math.max(
                                                    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
                                                    endDate.getMonth() -
                                                    startDate.getMonth(), 0)
                                            };


                                            itc['months_duration'] = monthsBtwnDates(dates[0], dates[1]) + 1
                                        })


                                        // Aqui debemos obtener los datos de las dosis
                                        let dosages = []
                                        new Promise((resolver, rechazar) => {
                                            db.collection(`centers/${doc.id}/itcs/${doc_itc.id}/dosages`)
                                                .get().then(async function (dosages_data) {
                                                dosages_data.forEach(dosage => {
                                                    let dosis = {}
                                                    dosis = dosage.data()
                                                    dosis['id'] = dosage.id

                                                    let dos_date = dosage.data().dosage_date


                                                    new Promise((result, rechazado) => {

                                                        let dates_dosage = []
                                                        dates_dosage.push(st_date)
                                                        dates_dosage.push(dos_date)


                                                        result(dates_dosage)
                                                        // 0 -> start date, d1
                                                        // 1 -> actual date,d2

                                                    }).then(dates => {
                                                        const monthsBtwnDates_dosages = (startDate, endDate) => {
                                                            startDate = new Date(startDate);
                                                            endDate = new Date(endDate);
                                                            return Math.max(
                                                                (endDate.getFullYear() - startDate.getFullYear()) * 12 +
                                                                endDate.getMonth() -
                                                                startDate.getMonth(),
                                                                0
                                                            ) - 1
                                                        };

                                                        // console.log("Fechas : ", dates, " Duration: ", duration )
                                                        dosis['months_duration'] = monthsBtwnDates_dosages(dates[0], dates[1]) + 1
                                                    })

                                                    dosages.push(dosis)
                                                })

                                                // En este punto podremos revisar los datos de las dosis
                                                len_dosages[doc_itc.data().code_itc] = dosages.length
                                                itc['len_dosages'] = dosages.length

                                                if (itc['status_itc'] === "On treatment") {
                                                    itc['flag_update'] = itc['months_duration'] > itc['len_dosages']
                                                    // doc_itc.data().flag_update = itc['flag_update']

                                                    if (itc['flag_update']) {
                                                        cuenta.flag_update = itc['flag_update']
                                                    }


                                                } else {
                                                    itc['flag_update'] = false
                                                }
                                            })

                                            resolver(dosages)

                                            // reject(rechazar)
                                        }).then(dosis => {

                                            // console.log(dosis)
                                            // console.log("Dosages: ", dosis)
                                            //Movemos esto a la parte superior para introducir t odo de una vez
                                            itc['code_itc'] = doc_itc.data().code_itc
                                            itc['dosages'] = dosis
                                            // itc['n_dosages'] = len_dosages[doc_itc.data().code_itc]


                                            itcs_dic[doc_itc.data().code_itc] = itc
                                            itcs.push(itc)

                                            let resolving = {}
                                            resolving.itcs = itcs
                                            resolving.itcs_dic = itcs_dic
                                            resolve(resolving)

                                        })

                                    })

                            })

                        }).then(data => {


                            // let cuenta = doc.data()
                            let activos = () => {
                                let counter = 0;
                                for (let i = 0; i < data.itcs.length; i++) {
                                    if (data.itcs[i].status_itc === 'On treatment') {
                                        counter++
                                    }
                                }
                                return counter;
                            }


                            let inactivos = () => {
                                let counter = 0;
                                for (let i = 0; i < data.itcs.length; i++) {
                                    if (data.itcs[i].status_itc !== 'On treatment') {
                                        counter++
                                    }
                                }
                                return counter;
                            }

                            // console.log(data.itcs)

                            cuenta.itcs = data.itcs
                            cuenta.itc_actives = activos()
                            cuenta.itc_inactives = inactivos()


                            // cuenta.flag_update = false
                            // for (let i = 0; i < data.itcs.length; i++) {
                            //     if ((data.itcs[i].status_itc === 'On treatment')) {
                            //         new Promise((res, rej) => {
                            //             let flag = data.itcs[i].flag_update
                            //             console.log(flag)
                            //             res(flag)
                            //
                            //         }).then(d => {
                            //             if (d) {
                            //                 cuenta.flag_update = true
                            //             }
                            //         })
                            //     }
                            // }


                            // console.log(cuenta)

                            // console.log(cuenta)
                            cuentas.push(cuenta)

                            cuenta.itcs = data.itcs_dic
                            cuentas_dicc[cuenta.code_hospital] = cuenta


                        })
                        // .finally(() => {
                        //
                        // })

                    });


                    // console.log(cuentas_dicc)


                    commit('setCenters', cuentas_dicc)
                    commit('setAccounts', cuentas)

                    setTimeout(() => {
                        commit('loadingFirebase', false)

                    }, 6000)


                })


        },


        async filterBySolution(data, solution) {
            const filteredData = {};
            Object.entries(data).forEach(([key, value]) => {
                const itcs = value.itcs;
                const filteredItcs = {};
                Object.entries(itcs).forEach(([code, codeData]) => {
                    if (codeData.solution === solution) {
                        filteredItcs[code] = codeData;
                    }
                });
                if (Object.keys(filteredItcs).length > 0) {
                    filteredData[key] = {...value, itcs: filteredItcs};
                }
            });
            return filteredData;
        },

        async getAccounts({commit}, scope) {

            commit('loadingFirebase', true)


            let actual_date = new Date().toISOString().split('T')[0]
            var cuentas = []
            var cuentas_dicc = {}
            var logguedUser;
            var len_dosages = {}


            let query = 0
            let database = 'centers'


            if ((this.state.user.ut.ut === 1) || ((this.state.user.ut.ut === 3))) {
                query = db.collection(`${database}`).where('status_hospital', "in", scope)

            } else if (this.state.user.ut.ut === 2) {
                query = db.collection(`${database}`)
                    .where('status_hospital', "in", scope)
                    .where('kamMail', '==', this.state.user.email)
            } else if (this.state.user.ut.ut === 0) {
                query = db.collection(`${database}`).where('status_hospital', "in", scope)

            }


            query.get()
                .then(res => {
                    res.forEach(doc => {
                        let itcs = []
                        let itcs_dic = {}
                        //añadimos lo primero la cuenta, para ir poniendole info
                        let cuenta = doc.data()


                        cuenta.flag_iclusig = false
                        cuenta.flag_minjuvi = false
                        cuenta.tags = ''
                        cuenta.tags += cuenta.code_hospital + ' '


                        new Promise((resolve) => {
                            db.collection(`${database}/${doc.id}/itcs`)
                                .get().then(async function (itcs_data) {

                                /*
                                * Hay casos en los que al no existir subcolecciones, se pierden esos
                                * datos. Para evitarlo tenemos que comprobar si los elementos que estamos
                                * iterando tienen esas subcolecciones o no. Para hacer esa comprobacion
                                * tenemos que tenerlos en cuenta en este punto para el primer nivel
                                * */


                                // TODO: ESTA FUNCION HAY QUE PERSONALIZARLA .....
                                if (itcs_data.docs.length === 0) {

                                    //Los metemos directamente en los datos
                                    doc.data().minjuvi_actives = 0
                                    doc.data().minjuvi_inactives = 0
                                    doc.data().minjuvi_total = 0


                                    doc.data().iclusig_actives = 0
                                    doc.data().iclusig_inactives = 0
                                    doc.data().iclusig_total = 0


                                    cuentas.push(doc.data())
                                    cuentas_dicc[doc.id] = doc.data()

                                }

                                itcs_data.forEach(
                                    doc_itc => {


                                        let itc = {}
                                        itc = doc_itc.data()
                                        itc['id'] = doc_itc.id
                                        cuenta.tags += itc.id + ' '


                                        // La siguiente promesa nos ayudará a obtener la duración del
                                        // tratamiento.
                                        let st_date = doc_itc.data().start_date
                                        let end_date = doc_itc.data().end_date
                                        new Promise((result, rechazado) => {

                                            let dates = []
                                            dates.push(st_date)

                                            if (end_date.length > 0) {

                                                dates.push(end_date)

                                            } else {
                                                dates.push(actual_date)
                                            }


                                            // console.log(dates)
                                            result(dates)
                                            // 0 -> start date, d1
                                            // 1 -> actual date,d2

                                        }).then(dates => {
                                            const monthsBtwnDates = (startDate, endDate) => {
                                                startDate = new Date(startDate);
                                                endDate = new Date(endDate);
                                                return Math.max(
                                                    (endDate.getFullYear() - startDate.getFullYear()) * 12 +
                                                    endDate.getMonth() -
                                                    startDate.getMonth(), 0)
                                            };

                                            // console.log("Fechas : ", dates, " Duration: ", duration )
                                            itc['months_duration'] = monthsBtwnDates(dates[0], dates[1]) + 1

                                        })


                                        // Aqui debemos obtener los datos de las dosis
                                        let dosages = []
                                        new Promise((resolver, rechazar) => {
                                            db.collection(`${database}/${doc.id}/itcs/${doc_itc.id}/dosages`)
                                                .get().then(async function (dosages_data) {
                                                dosages_data.forEach(dosage => {
                                                    let dosis = {}
                                                    dosis = dosage.data()
                                                    dosis['id'] = dosage.id

                                                    let dos_date = dosage.data().dosage_date


                                                    new Promise((result) => {

                                                        let dates_dosage = []
                                                        dates_dosage.push(st_date)
                                                        dates_dosage.push(dos_date)

                                                        // console.log(dates)
                                                        result(dates_dosage)
                                                        // 0 -> start date, d1
                                                        // 1 -> actual date,d2

                                                    }).then(dates => {
                                                        const monthsBtwnDates_dosages = (startDate, endDate) => {
                                                            startDate = new Date(startDate);
                                                            endDate = new Date(endDate);
                                                            return Math.max(
                                                                (endDate.getFullYear() - startDate.getFullYear()) * 12 +
                                                                endDate.getMonth() -
                                                                startDate.getMonth(),
                                                                0
                                                            ) - 1
                                                        };

                                                        // console.log("Fechas : ", dates, " Duration: ", duration )
                                                        dosis['months_duration'] = monthsBtwnDates_dosages(dates[0], dates[1]) + 1
                                                    })

                                                    dosages.push(dosis)
                                                })

                                                // En este punto podremos revisar los datos de las dosis
                                                len_dosages[doc_itc.data().code_itc] = dosages.length
                                                itc['len_dosages'] = dosages.length


                                                if (itc['status_itc'] === "On treatment") {
                                                    itc['flag_update'] = itc['months_duration'] > itc['len_dosages']
                                                    // doc_itc.data().flag_update = itc['flag_update']

                                                    if (itc['flag_update'] && (itc.solution === 'minjuvi')) {

                                                        cuenta.flag_minjuvi = itc['flag_update']
                                                        cuenta.tags += ' minjuvi_pending '
                                                    }

                                                    if (itc['flag_update'] && (itc.solution === 'iclusig')) {
                                                        cuenta.flag_iclusig = itc['flag_update']
                                                        cuenta.tags += ' iclusig_pending '

                                                    }


                                                } else {
                                                    itc['flag_update'] = false
                                                }
                                            })

                                            resolver(dosages)

                                            // reject(rechazar)
                                        }).then(dosis => {

                                            // console.log(dosis)
                                            // console.log("Dosages: ", dosis)
                                            //Movemos esto a la parte superior para introducir t odo de una vez
                                            itc['code_itc'] = doc_itc.data().code_itc
                                            itc['dosages'] = dosis
                                            // itc['n_dosages'] = len_dosages[doc_itc.data().code_itc]


                                            itcs_dic[doc_itc.data().code_itc] = itc
                                            itcs.push(itc)


                                            let resolving = {}
                                            resolving.itcs = itcs
                                            resolving.itcs_dic = itcs_dic
                                            resolve(resolving)

                                        })

                                    })

                            })

                        }).then(data => {

                            let activos = (solution) => {
                                let counter = 0;
                                for (let i = 0; i < data.itcs.length; i++) {
                                    if ((data.itcs[i].status_itc === 'On treatment') && (data.itcs[i].solution === solution)) {
                                        counter++
                                    }
                                }
                                return counter;
                            }


                            let inactivos = (solution) => {
                                let counter = 0;
                                for (let i = 0; i < data.itcs.length; i++) {
                                    if ((data.itcs[i].status_itc !== 'On treatment') && (data.itcs[i].solution === solution)) {
                                        counter++
                                    }
                                }
                                return counter;
                            }


                            cuenta.itcs = data.itcs

                            cuenta.iclusig_actives = activos('iclusig')

                            if (cuenta.iclusig_actives > 0) {
                                cuenta.tags += ' iclusig '
                            }


                            cuenta.iclusig_inactives = inactivos('iclusig')
                            // cuenta.iclusig_total


                            cuenta.minjuvi_actives = activos('minjuvi')

                            if (cuenta.minjuvi_actives > 0) {
                                cuenta.tags += ' minjuvi '
                            }


                            cuenta.minjuvi_inactives = inactivos('minjuvi')
                            // cuenta.minjuvi_total


                            cuentas.push(cuenta)

                            cuenta.itcs = data.itcs_dic
                            cuentas_dicc[cuenta.code_hospital] = cuenta


                        })
                    });


                    // console.log("Diccionario de cuentas ", cuentas_dicc)
                    // console.log("Array de cuentas ", cuentas)

                    commit('setCenters', cuentas_dicc)
                    commit('setAccounts', cuentas)

                    setTimeout(() => {
                        commit('loadingFirebase', false)

                    }, 6000)


                })


        }
        ,


        calcDate(date1, date2) {
            var diff = Math.floor(date1.getTime() - date2.getTime());
            var day = 1000 * 60 * 60 * 24;

            var days = Math.floor(diff / day);
            return Math.floor(days / 31);


        }
        ,

// Filtrar el objeto dado a la medicina seleccionada
        filterSolution(obj, sol) {
            var resultado = {};
            for (var key in obj) {
                if (obj[key].color === sol) {
                    resultado[key] = obj[key];
                }
            }
            return resultado;
        }
        ,


        updateAccount({commit}, account) {

            commit('loadingFirebase', true)
            db.collection('centers').doc(account.code_hospital).update({
                status_hospital: account.status_hospital
            }).then(
                () => {
                    commit('loadingFirebase', false)
                }
            )
        }
        ,

        insertHosp({commit}, account) {
            db.collection("centers").doc(account.code_hospital).set({
                area: account.area,
                city: account.city,
                code_hospital: account.code_hospital,
                country: account.country,
                flag_update: true,
                hospital: account.hospital,
                itc_actives: 0,
                itc_inactives: 0,
                kamMail: account.kamMail,
                kamName: account.kamName,
                region: account.region,
                status_hospital: account.status_hospital
            })
                .then(() => {

                })
                .catch((error) => {

                });
            commit('loadingFirebase', true)


            db.collection('centers')
                .doc(account.code_hospital)
                .set(account)
                .then(
                    () => {
                        commit('loadingFirebase', false)
                    })


        }
        ,

        updateHosp({commit}, h_status) {

            commit('loadingFirebase', true)
            database.ref('/hospitals/' + h_status.code_hospital).update({
                status_hospital: h_status.status_hospital,
            }).then(() => {
                    commit('loadingFirebase', false)
                }
            );
        },


        updateKam({commit}, city, oldKam, newKam) {
            // Actualiza el campo 'kamMail' de los documentos que cumplan con la condición
            const query = db.collection('centers').where('city', '==', city).where('kamMail', '==', oldKam);
            query.get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const docRef = db.collection('centers').doc(doc.id);
                    docRef.update({kamMail: newKam});
                });

            });
        },

        updateTreatmentsStatusMinjuvi({commit}, account) {
            commit('loadingFirebase', true)
            db.collection('centers').doc(account.code_hospital).update({
                minjuvi_actives: account.minjuvi_actives,
                minjuvi_inactives: account.minjuvi_inactives

            }).then(
                () => {
                    commit('loadingFirebase', false)

                }
            )
        },


        updateTreatmentsStatusIclusig({commit}, account) {
            commit('loadingFirebase', true)
            db.collection('centers').doc(account.code_hospital).update({
                iclusig_actives: account.iclusig_actives,
                iclusig_inactives: account.iclusig_inactives
            }).then(
                () => {
                    commit('loadingFirebase', false)

                }
            )
        },


        updateCounterIclusig({commit}, account) {
            commit('loadingFirebase', true)
            db.collection('centers').doc(account.code_hospital).update({
                counter_iclusig: account.counter_iclusig
            }).then(
                () => {
                    commit('loadingFirebase', false)

                }
            )
        },

        updateCounterMinjuvi({commit}, account) {
            commit('loadingFirebase', true)
            db.collection('centers').doc(account.code_hospital).update({
                counter_minjuvi: account.counter_minjuvi
            }).then(
                () => {
                    commit('loadingFirebase', false)

                }
            )
        }
        ,

        updateCounter({commit}, itc) {
            commit('loadingFirebase', true)
            db.collection('centers')
                .doc(itc.code_hospital)
                .collection('itcs')
                .doc(itc.code_itc)
                .update({
                    counter_dosages: itc.counter_dosages
                }).then(
                () => {
                    commit('loadingFirebase', false)
                }
            )
        },


        requestNewUser({commit}, user) {
            db.collection('requests')
                .doc(user.phone)
                .set(user)
        },


        updateTreatmentsStatus({commit}, account) {
            commit('loadingFirebase', true)
            db.collection('centers').doc(account.code_hospital).update({
                itc_actives: account.itc_actives,
                itc_inactives: account.itc_inactives

            }).then(
                () => {
                    commit('loadingFirebase', false)

                }
            )
        },


        updateDatabaseITC({commit}, itc) {
            // console.log("Insertamos itc", itc)
            //quitamos las dosages para no añadir otra matriz (las dosages se actualizan a parte
            delete itc.dosages
            db.collection('centers')
                .doc(itc.code_hospital)
                .collection('itcs')
                .doc(itc.code_itc)
                .update(itc)
        }
        ,

        updateDatabaseDosages({commit}, dosage) {
            // console.log(dosage)
            db.collection('centers')
                .doc(dosage.code_hospital)
                .collection('itcs')
                .doc(dosage.code_itc)
                .collection('dosages')
                .doc(dosage.code_dosage)
                .update(dosage)
        },

        updateCenters({commit}, data) {
            db.collection('centers')
                .doc(data.code_hospital)
                .update({"cnh": data.cnh, "client": data.client, cart: data.cart})
        },

        insertDatabaseITC({commit}, itc) {
            db.collection('centers')
                .doc(itc.code_hospital)
                .collection('itcs')
                .doc(itc.code_itc)
                .set(itc)
        },

        insertMovedItc({commit}, itc) {
            db.collection('moved')
                .doc(itc.code_itc)
                .set(itc)
        },


        insertDatabaseDosage({commit}, dosage) {
            // console.log("insertamos dosis", dosage)
            db.collection('centers')
                .doc(dosage.code_hospital)
                .collection('itcs')
                .doc(dosage.code_itc)
                .collection('dosages')
                .doc(dosage.code_dosage)
                .set(dosage)
        }
        ,

        deleteDatabaseITC({commit}, itc) {
            //Borramos todas las dosis primero
            db.collection('centers')
                .doc(itc.code_hospital)
                .collection('itcs')
                .doc(itc.code_itc)
                .collection('dosages')
                .get()
                .then(snapshot => {
                    snapshot.docs.forEach(doc => {
                        doc.ref.delete()
                    });
                });

            //Borramos el itc
            db.collection('centers')
                .doc(itc.code_hospital)
                .collection('itcs')
                .doc(itc.code_itc)
                .delete()
        }
        ,

        deleteDatabaseDosage({commit}, dosage) {
            db.collection('centers')
                .doc(dosage.code_hospital)
                .collection('itcs')
                .doc(dosage.code_itc)
                .collection('dosages')
                .doc(dosage.code_dosage)
                .delete()

        }
        ,
        async getUserName() {
            let logguedUser = await db.collection('users').doc(this.state.user.uid).get()
            return logguedUser.data()

        }
        ,


// Funtion  sort by months
        dynamicSort(property) {
            var sortOrder = 1;
            if (property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a, b) {
                /* next line works with strings and numbers,
                 * and you may want to customize it to your needs
                 */
                var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        },


    },
    modules: {},

    getters: {

        getUser() {
            return this.user
        }
        ,

        userCheck(state) {
            if (state.user === null) {
                return false
            } else {
                return true
            }
        }
        ,


    }
    ,


})
